import { Box, LinkList, LinkListHeading, ListItem } from '@vp/swan'
import { PageSeoDescription, PageSeoTitle } from 'components/seo/page-seo.component'
import { Link, PageProps } from 'gatsby'
import { UserProvider } from 'lib/user'
import { FC } from 'react'
import { IntlProvider } from 'react-intl'
import { PageContextType } from 'types/application.types'
import { noOp, usePostcardPRDCheck } from 'utilities/functions.utils'
import '../styles/index.scss'

const IndexPage: FC<PageProps<unknown, PageContextType>> = ({ pageContext }) => {
  const isPostcard = usePostcardPRDCheck()
  const aListType = isPostcard ? 'a mailing' : 'an address'
  return (
    <IntlProvider messages={pageContext.messages} locale={pageContext.localeCulture} defaultLocale="en-US" onError={noOp}>
      <UserProvider localeCulture="en-US">
        <Box align="center" mt={10}>
          <PageSeoTitle content="Mailing services" />
          <PageSeoDescription content={`Upload ${aListType} list from your computer or choose a saved address list to get started.`} />
          <LinkListHeading>Available pages</LinkListHeading>
          {/* TODO check the deleted skin="standard" */}
          <LinkList>
            <ListItem>
              <Link to="/msp/mailing-services">EN - Upsell</Link>
            </ListItem>
            <ListItem>
              <Link to="/es/msp/mailing-services">ES - Upsell</Link>
            </ListItem>
            <ListItem>
              <Link to="/msp/upload">EN - Upload</Link>
            </ListItem>
            <ListItem>
              <Link to="/es/msp/upload">ES - Upload</Link>
            </ListItem>
          </LinkList>
        </Box>
      </UserProvider>
    </IntlProvider>
  )
}
export default IndexPage
